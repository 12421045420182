import { memo, useCallback, useState } from 'react';
import { Button, GestureResponderEvent, Image, Linking, StyleSheet, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Feather } from '@expo/vector-icons';

import * as Clipboard from 'expo-clipboard';

import { Text, View } from '../components/Themed';
import { RootState } from '../store';
import MusicServiceRow from '../components/MusicServiceRow';
import { deleteFromHistory } from '../features/history/historySlice';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from '../types';

const onServicePress = (url: string | undefined, share: boolean) => {
  if (url != null)
    Linking.openURL(url);

  return true;
};

const SongDetailScreen = ({ navigation }: NativeStackScreenProps<RootStackParamList>) => {

  const historyEntry = useSelector((state: RootState) => state.detail.data);

  const dispatch = useDispatch();
  
  const onPressAppleMusic = useCallback((share: boolean) => {
    return onServicePress(historyEntry?.links.appleMusic, share);
  }, [historyEntry]);

  const onPressSpotify = useCallback((share: boolean) => {
    return onServicePress(historyEntry?.links.spotify, share);
  }, [historyEntry]);

  const onPressYouTube = useCallback((share: boolean) => {
    return onServicePress(historyEntry?.links.youtube, share);
  }, [historyEntry]);

  const onPressDelete = useCallback((event: GestureResponderEvent) => {
    if (historyEntry == null)
      return true;
    
    dispatch(deleteFromHistory(historyEntry));

    navigation.goBack();

    return true;
  }, [historyEntry]);

  const onPressShareAll = useCallback((event: GestureResponderEvent) => {
    if (historyEntry == null)
      return true;
    
    Clipboard.setString(`${historyEntry.title} by ${historyEntry.artist} (${historyEntry.album})

Apple Music: ${historyEntry.links.appleMusic ?? '(not found)'}
Spotify: ${historyEntry.links.spotify ?? '(not found)'}
YouTube: ${historyEntry.links.youtube ?? '(not found)'}

(Shared with SongButler - https://songbutler.app/)`);
    
    setShared(true);

    return true;
  }, [historyEntry]);

  const [shared, setShared] = useState(false);

  if (historyEntry == null)
    return (
      <Text>Error loading data.</Text>
    );

  return (
    <View style={{ flex: 1, flexDirection: 'row' }}>
      <View style={styles.containerWeb}>
        <Image style={styles.albumArt} source={{ uri: historyEntry?.albumArtURL }} />
        <Text style={styles.title}>{historyEntry.title}</Text>
        <Text>{historyEntry.artist}</Text>
        <Text>{historyEntry.album}</Text>
      </View>

      <View style={styles.containerWeb}>
        <TouchableOpacity>
          <MusicServiceRow musicServiceName='Listen on Apple Music' musicServiceImageSource={require('../assets/images/apple_music.png')} onPress={onPressAppleMusic} style={styles.shareRow} />
        </TouchableOpacity>
        
        <TouchableOpacity>
          <MusicServiceRow musicServiceName='Listen on Spotify' musicServiceImageSource={require('../assets/images/spotify.png')} onPress={onPressSpotify} style={styles.shareRow} />
        </TouchableOpacity>
        
        <TouchableOpacity>
          <MusicServiceRow musicServiceName='View on YouTube' musicServiceImageSource={require('../assets/images/youtube.png')} onPress={onPressYouTube} style={styles.shareRow} />
        </TouchableOpacity>

        { !shared && <Button title="Share All" onPress={onPressShareAll} /> }
        { shared && <Text>Copied to clipboard!</Text> }

        <TouchableOpacity>
          <View style={styles.deleteRow} onStartShouldSetResponder={onPressDelete}>
            <Feather name="trash" size={18} color='red' style={{ paddingEnd: 8 }} />
            <Text style={{ color: 'red' }}>Delete</Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  albumArt: {
    width: 250,
    height: 250,
    borderRadius: 10,
    borderColor: 'gray',
    borderWidth: 3,
    paddingBottom: 10
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 25
  },
  containerWeb: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 25,
    width: '50%'
  },
  musicService: {
    width: 25,
    height: 25,
    aspectRatio: 1,
    resizeMode: 'contain'
  },
  title: {
    fontSize: 32,
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: 'center'
  },
  shareAllRow: {
    flexDirection: 'row', 
    alignItems: 'center',
    backgroundColor: 'transparent'
  },
  deleteRow: {
    flexDirection: 'row', 
    alignItems: 'center',
    backgroundColor: 'transparent',
    paddingTop: 24
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  lightThemeShare: {
    color: '#242c40',
  },
  darkThemeShare: {
    color: '#d0d0c0',
  },
  shareRow: {
    flexDirection: 'row', 
    alignItems: 'center', 
    paddingBottom: 25,
    backgroundColor: 'transparent'
  }
});

export default memo(SongDetailScreen);