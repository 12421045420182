import { configureStore, applyMiddleware, getDefaultMiddleware } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import detailReducer from './features/detail/detailSlice';
import historyReducer from './features/history/historySlice';
import { songButlerAPI } from './services/songButlerAPI';

export const store = configureStore({
    reducer: {
        detail: detailReducer,
        history: historyReducer,
        [songButlerAPI.reducerPath]: songButlerAPI.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(songButlerAPI.middleware)
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch