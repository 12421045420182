import React, { useCallback, useEffect, useState } from 'react';
import { ActivityIndicator, Image, ImageBackground, Platform, Pressable, StyleSheet, TextInput } from 'react-native';
import ShazamKit from '@edualm/react-native-shazam-kit';
import NowPlaying, { NowPlayingState } from '@edualm/react-native-now-playing';

import { Text, View } from '../components/Themed';
import { APISongResponse, RootTabScreenProps } from '../types';

import { useGetTrackDataFromQueryMutation, useGetTrackDataFromURLMutation } from '../services/songButlerAPI';
import { useDispatch } from 'react-redux';

import { setDetail } from '../features/detail/detailSlice';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import CuteButton from '../components/CuteButton';
import CoreDataManager from '../native/CoreDataManager';
import { transform } from '../helpers/APITransformer';
import { addToHistory } from '../features/history/historySlice';

export default function SearchScreen({ navigation }: RootTabScreenProps<'SearchTab'>) {
  
  const [input, setInput] = useState('');
  const [lastError, setLastError] = useState('');
  const [showShazam, setShowShazam] = useState(false);
  const [isShazamLoading, setIsShazamLoading] = useState(false);
  const [nowPlaying, setNowPlaying] = React.useState<NowPlayingState | undefined>();
  
  const [performSearch, { isLoading: isSearchLoading }] = useGetTrackDataFromQueryMutation();
  const [performLinkQuery, { isLoading: isLinkQueryLoading }] = useGetTrackDataFromURLMutation();

  const dispatch = useDispatch();

  const parseResponse = (result: { data: APISongResponse } | { error: FetchBaseQueryError | SerializedError }) => {
    if ("data" in result && result.data !== null) {
      const historyEntry = transform(result.data);
      
      dispatch(setDetail(historyEntry));
      dispatch(addToHistory(historyEntry));

      navigation.navigate('SongDetail');
    } else {
      setLastError("An error has occurred while processing your request. Maybe try again?");
    }
  }

  const onSearchPressed = async () => {
    const result = await performSearch(input);

    setLastError("");
    setInput("");
    
    parseResponse(result);
  }

  const onListenPressed = async () => {
    try {
      setIsShazamLoading(true);

      const shazamResult = await ShazamKit.listen();

      let result;

      if (shazamResult.appleMusicURL != null) {
        result = await performLinkQuery(shazamResult.appleMusicURL);
      } else {
        result = await performSearch(shazamResult.title + ' - ' + shazamResult.artist);
      }

      parseResponse(result);
    } catch (e) {
      setLastError("An error has occurred while matching. Maybe try again?");
    } finally {
      setIsShazamLoading(false);
    }
  };

  const onNowPlayingPressed = async () => {
    const result = await performSearch(nowPlaying?.item?.title + ' - ' + nowPlaying?.item?.artist);

    setLastError("");
    setInput("");
    
    parseResponse(result);
  }

  const nowPlayingCallback = React.useCallback(setNowPlaying, []);

  if (Platform.OS === 'ios' || Platform.OS === 'android') {
    useEffect(() => {
      (async () => {
        setShowShazam(await ShazamKit.isSupported());
      })();
  
      NowPlaying.startObserving(nowPlayingCallback, 'default');
    });
  }

  const isLoading = (isShazamLoading || isSearchLoading || isLinkQueryLoading);

  CoreDataManager.getSongs();

  return (
    <View style={styles.container}>
      <ImageBackground source={require('../assets/images/butler.png')} resizeMode='contain' style={styles.background} imageStyle={styles.backgroundImage}>
        { isLoading && <ActivityIndicator size="large" color="purple" /> }
        { !isLoading && (
          <>
            { lastError !== "" && (
              <Text style={styles.errorText}>{lastError}</Text>
            )}
            <TextInput style={styles.input} onChangeText={setInput} value={input} onEndEditing={onSearchPressed} />
            <View style={styles.buttonsContainer}>
              <CuteButton onPress={onSearchPressed} title="Search" />
              { showShazam && <CuteButton style={{ paddingStart: 10 }} onPress={onListenPressed} title="Listen" /> }
            </View>
            { nowPlaying?.isPlaying && (
              <>
                <View style={styles.nowPlayingDividerView} />
                <Pressable style={styles.nowPlayingPressable} onPress={onNowPlayingPressed}>
                  <View style={styles.nowPlayingContainer}>
                    { nowPlaying.item?.artwork && <Image style={styles.albumArt} source={{ uri: 'data:image/png;base64,' + nowPlaying.item?.artwork }} /> }
                    <View style={{ backgroundColor: 'transparent', paddingStart: nowPlaying.item?.artwork ? 16 : 4, flexShrink: 1, flex: 1 }}>
                      <Text style={{ fontWeight: 'bold', flexShrink: 1 }}>{nowPlaying?.item?.title}</Text>
                      <Text style={{ flexShrink: 1 }}>{nowPlaying?.item?.artist}</Text>
                      <Text style={{ flexShrink: 1 }}>{nowPlaying?.item?.album}</Text>
                    </View>
                    <Text style={{ alignSelf: 'center', paddingStart: 16, marginEnd: 8 }}>🔎</Text>
                  </View>
                </Pressable>
              </>
            ) }
          </>
         )}
      </ImageBackground>
    </View>
  );
}

const styles = StyleSheet.create({
  background: {
    flex: 1,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  backgroundImage: {
    opacity: 0.25,
    alignSelf: 'flex-end',
    height: 350,
    top: undefined
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonsContainer: {
    flexDirection: 'row', 
    backgroundColor: 'transparent'
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  input: {
    color: 'black',
    backgroundColor: 'gray',
    width: '80%',
    height: 40,
    margin: 12,
    borderWidth: 1,
    borderRadius: 15, 
    padding: 10,
  },
  errorText: {
    padding: 8, 
    borderColor: 'red',
    borderWidth: 2, 
    borderRadius: 8,
    textAlign: 'center'
  },
  nowPlayingPressable: { 
    width: '80%', 
    backgroundColor: 'gray',
    borderRadius: 8,
    borderColor: 'white',
    borderWidth: 2,
  },
  nowPlayingContainer: {
    flexDirection: 'row', 
    margin: 8,
    alignItems: 'center', 
    backgroundColor: 'transparent'
  },
  albumArt: {
    width: 64, 
    height: 64, 
    borderWidth: 1, 
    borderRadius: 5,
    paddingRight: 8
  },
  nowPlayingDividerView: {
    padding: 24, 
    backgroundColor: 'transparent'
  }
});
