import { Song, OldHistoryEntry } from "../../types";

function migrate() {
    const serializedHistoryJSON = localStorage.getItem('history');

    if (serializedHistoryJSON == null)
        return;
    
    const serializedHistory: OldHistoryEntry[] = JSON.parse(serializedHistoryJSON);

    const newHistory: Song[] = serializedHistory.map(old => ({
        album: old.entry.attributes.album,
        albumArtURL: old.entry.attributes.album_art_url,
        artist: old.entry.attributes.artist,
        title: old.entry.attributes.title,

        queryDates: [old.date],
        lastQueryDate: old.date,

        links: {
            appleMusic: old.entry.links.apple_music,
            spotify: old.entry.links.spotify,
            youtube: old.entry.links.youtube
        }
    }));

    localStorage.setItem('history', JSON.stringify(newHistory));
}

function getDeserializedHistory(): Song[] {
    const serializedHistory = localStorage.getItem('history');
    let history: Song[] = [];

    if (serializedHistory != null)
        history = JSON.parse(serializedHistory);
    else
        history = [];
    
    return history;
}

function persistHistory(history: Song[]) {
    localStorage.setItem('history', JSON.stringify(history));
}

export async function loadHistory(): Promise<Song[]> {
    await migrate();

    const serializedHistory = localStorage.getItem('history');

    if (serializedHistory === null)
        return [];

    return JSON.parse(serializedHistory);
}

export async function addToHistory(song: Song) {
    let history = getDeserializedHistory();
    
    let updatedEntry = false;

    for (const entry of history) {
        if (entry.album == song.album &&
            entry.artist == song.artist &&
            entry.title == song.title) {
            const date = Date.now();

            entry.lastQueryDate = date;
            entry.queryDates = [...entry.queryDates, date];

            updatedEntry = true;

            break;
        }
    }

    if (!updatedEntry) {
        history = [...history, song];
    }

    persistHistory(history);
}

export async function removeFromHistory(song: Song) {
    let history = getDeserializedHistory();

    let newHistory = history.filter(entry => {
        return !(entry.album == song.album &&
                entry.artist == song.artist &&
                entry.title == song.title);
    });

    persistHistory(newHistory);
}