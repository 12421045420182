import { Button, GestureResponderEvent, Image, StyleSheet } from 'react-native';
import { Feather } from '@expo/vector-icons';

import useColorScheme from '../hooks/useColorScheme';
import { Text, View } from '../components/Themed';
import { MusicServiceRowProps } from '../types';

export default function MusicServiceRow({ musicServiceName, musicServiceImageSource, onPress, style }: MusicServiceRowProps) {

    const colorScheme = useColorScheme();
  
    const shareColorScheme = colorScheme === 'light' ? styles.lightThemeShare : styles.darkThemeShare;
  
    return (
      <View style={style}>
        <View onStartShouldSetResponder={(event: GestureResponderEvent) => { return onPress(false); }} style={styles.musicServiceInner}>
          <Image source={musicServiceImageSource} style={styles.musicService} />
          <View style={{ padding: 5 }} />
          <Text>{musicServiceName}</Text>
        </View>
      </View>
    )
  }

  const styles = StyleSheet.create({
    musicService: {
      width: 25,
      height: 25,
      aspectRatio: 1,
      resizeMode: 'contain'
    },
    musicServiceInner: {
      flexDirection: 'row', 
      alignItems: 'center'
    },
    lightThemeShare: {
      color: '#242c40',
    },
    darkThemeShare: {
      color: '#d0d0c0',
    },
  });