import { Song } from "../types";

async function getSongs(): Promise<Song[]> {
    return [];
}

async function addSong(song: Song) {}

async function removeSong(song: Song) {}

export default { getSongs, addSong, removeSong };