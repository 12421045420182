import { APISongResponse, Song } from "../types";

export function transform(responseData: APISongResponse): Song {
    const date = Date.now();

    return {
        links: {
            appleMusic: responseData.links.apple_music,
            spotify: responseData.links.spotify,
            youtube: responseData.links.youtube
        },

        album: responseData.attributes.album,
        albumArtURL: responseData.attributes.album_art_url,
        artist: responseData.attributes.artist,
        title: responseData.attributes.title,

        queryDates: [date],
        lastQueryDate: date
    }
}