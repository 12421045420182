import React, { ReactElement, useEffect } from "react";

import { useDispatch } from "react-redux";
import { updateHistory } from "../features/history/historySlice";

interface Props {
    children: ReactElement<any, any>
}

const StorageLoader: React.FC<Props> = props => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateHistory());
    });

    return props.children;
};

export default StorageLoader;