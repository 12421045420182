import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SongDetail } from '../types';

interface ErrorWithCode {
    code: string
};

type APIResponseError = {
    success: false;
    error: ErrorWithCode;
}

type APIResponseSuccess = {
    success: true;
    data: SongDetail;
}

export const songButlerAPI = createApi({
    reducerPath: 'songButlerAPI',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://api.songbutler.app/api/' }),
    endpoints: (builder) => ({
        getTrackDataFromQuery: builder.mutation<SongDetail, string>({
            query: (body) => ({
                url: 'search',
                method: 'POST',
                body
            }),
            transformResponse: (response: APIResponseSuccess) => response.data
        }),
        getTrackDataFromURL: builder.mutation<SongDetail, string>({
            query: (body) => ({
                url: 'link',
                method: 'POST',
                body
            }),
            transformResponse: (response: APIResponseSuccess) => response.data
        })
    })
});

export const { useGetTrackDataFromQueryMutation, useGetTrackDataFromURLMutation } = songButlerAPI;