import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Song } from '../../types';

interface DetailState {
    data: Song | null
}

const initialState: DetailState = { data: null };

export const detailSlice = createSlice({
    name: 'detail',
    initialState,
    reducers: {
        clearDetail: (state) => {
            state = initialState;
        },
        setDetail: (state, action: PayloadAction<Song>) => {
            state.data = action.payload;
        }
    }
});

export const { clearDetail, setDetail } = detailSlice.actions;

export default detailSlice.reducer;