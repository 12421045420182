import { useCallback } from 'react';
import { Image, FlatList, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import { Text, View } from '../components/Themed';
import { setDetail } from '../features/detail/detailSlice';
import { RootState } from '../store';
import { RootTabScreenProps, Song } from '../types';
import { Ionicons } from '@expo/vector-icons';
import useColorScheme from '../hooks/useColorScheme';

const ItemDivider = () => (
  <View style={styles.separator} />
);

export default function HistoryScreen({ navigation }: RootTabScreenProps<'HistoryTab'>) {

  const colorScheme = useColorScheme();

  const dispatch = useDispatch();

  const data = useSelector((state: RootState) => state.history.entries);

  const onHistoryPress = (item: Song) => {
    dispatch(setDetail(item));

    navigation.navigate('SongDetail');
  };

  const renderItem = useCallback((item: Song, colorScheme: string) => (
    <View style={styles.row} onStartShouldSetResponder={() => {onHistoryPress(item); return true;}}>
      <View style={{ paddingEnd: 10 }}>
        <Image style={styles.albumArt} source={{ uri: item.albumArtURL }} />
      </View>
      <View style={{ flex: 1, justifyContent: 'center' }}>
        <Text style={styles.rowTitle} numberOfLines={1}>{item.title}</Text>
        <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
          <Ionicons name="person-outline" size={12} color={colorScheme === 'light' ? 'black' : 'white'} style={{ marginEnd: 2 }} />
          <Text style={styles.rowSubtitle} numberOfLines={1}>{item.artist}</Text>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
          <Ionicons name="analytics-outline" size={12} color={colorScheme === 'light' ? 'black' : 'white'} style={{ marginEnd: 2 }} />
          <Text style={styles.rowSubtitle} numberOfLines={1}>Searched {item.queryDates.length} times</Text>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
          <Ionicons name="time-outline" size={12} color={colorScheme === 'light' ? 'black' : 'white'} style={{ marginEnd: 2 }} />
          <Text style={styles.rowSubtitle} numberOfLines={1}>Last search: {moment(item.lastQueryDate).format('LLL')}</Text>
        </View>
      </View>
    </View>
  ), []);

  return (
    <View>
      <FlatList data={data.slice().reverse()} 
        renderItem={(x) => renderItem(x.item, colorScheme)} 
        keyExtractor={item => JSON.stringify(item.queryDates)} 
        ItemSeparatorComponent={ItemDivider} 
      />
    </View>
  );
}

const styles = StyleSheet.create({
  albumArt: {
    width: 75,
    height: 75,
    borderRadius: 10,
    borderColor: 'gray',
    borderWidth: 2
  },
  row: {
    padding: 16,
    flex: 1,
    flexDirection: 'row'
  },
  rowTitle: {
    fontSize: 22,
    fontWeight: 'bold'
  },
  rowSubtitle: {
    fontSize: 12
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    backgroundColor: "#607D8B",
    height: 1,
    width: '100%',
  },
});
