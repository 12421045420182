import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Song } from '../../types';

import { 
    loadHistory as loadHistoryHelper, 
    addToHistory as addToHistoryHelper, 
    removeFromHistory as removeFromHistoryHelper 
} from './HistoryHelpers';

interface HistoryState {
    entries: Song[]
}

const initialState: HistoryState = { entries: [] };

export const historySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        setHistory: (state, action: PayloadAction<Song[]>) => {
            state.entries = action.payload;

            console.log(state.entries);
        }
    }
});

export const addToHistory = (song: Song) => async (dispatch: any) => {
    await addToHistoryHelper(song);

    return updateHistory()(dispatch);
};

export const deleteFromHistory = (song: Song) => async (dispatch: any) => {
    await removeFromHistoryHelper(song);

    return updateHistory()(dispatch);
};

export const updateHistory = () => async (dispatch: any) => {
    dispatch(historySlice.actions.setHistory(await loadHistoryHelper()));
};

export default historySlice.reducer;