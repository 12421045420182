import { GestureResponderEvent, Text, StyleSheet, Pressable, View, Platform } from 'react-native';
import useColorScheme from '../hooks/useColorScheme';

type Props = {
  onPress: (event: GestureResponderEvent) => void,
  title: string,
  style?: any
}

export default function CuteButton(props: Props) {
  const { onPress, title, style } = props;

  const colorScheme = useColorScheme();

  const buttonSchemeStyle = colorScheme === 'light' ? styles.buttonLightMode : styles.buttonDarkMode;
  const textSchemeStyle = (() => {
    switch (Platform.OS) {
      case 'web':
        return colorScheme === 'light' ? styles.textDarkMode : styles.textLightMode;
      default:
        return colorScheme === 'light' ? styles.textLightMode : styles.textDarkMode;
    }
  })();

  return (
    <View style={style}>
      <Pressable style={({pressed}) => ({
        ...styles.button, 
        ...buttonSchemeStyle,
        opacity: pressed ? 0.5 : 1
      })} onPress={onPress}>
        <Text style={{ ...styles.text, ...textSchemeStyle, color: 'white' }}>{title}</Text>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 32,
    borderRadius: 4,
    elevation: 3,
  },
  buttonLightMode: {
    backgroundColor: 'black',
  },
  buttonDarkMode: {
    backgroundColor: 'gray',
  },
  text: {
    fontSize: 16,
    lineHeight: 21,
    fontWeight: 'bold',
    letterSpacing: 0.25,
  },
  textLightMode: {
    color: 'white',
  },
  textDarkMode: {
    color: 'black',
  }
});