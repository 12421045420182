import { Ionicons } from '@expo/vector-icons';
import { StatusBar } from 'expo-status-bar';
import { ImageBackground, Linking, Platform, Pressable, ScrollView, StyleProp, StyleSheet, ViewStyle } from 'react-native';
import CuteButton from '../components/CuteButton';

import { Text, View } from '../components/Themed';
import { RootStackScreenProps } from '../types';

interface Feature {
  title: string;
  description: string;
}

let features: Feature[] = [
  { 
    title: '🤝 Share your now playing song', 
    description: 'Listening to a song on your Music app? SongButler can find that song on other services (Apple Music, Spotify or YouTube) and allow you to share the link with other people!'
  },
  (Platform.OS !== 'web') ? 
  {
    title: '🔀 Convert from other services',
    description: 'Found a song you like on YouTube (or similar) and want to add it to your Apple Music or Spotify library? Just copy the link to your clipboard, and the butler will take care of finding it for you!'
  } : { title: '', description: '' },
  {
    title: '📜 Search using lyrics',
    description: 'Know (part of) the lyrics of a song? Just type it into the search box and be amazed!'
  }
].filter(x => x.title != '');

function ApplicationFeature({ feature: { title, description }, style }: { feature: Feature, style: StyleProp<ViewStyle> }) {
  return (
    <View style={style}>
      <Text style={styles.featureTitle}>{title}</Text>
      <Text style={styles.featureDescription}>{description}</Text>
    </View>
  )
}

export default function InfoModalScreen({ navigation }: RootStackScreenProps<'InfoModal'>) {
  const onWebsiteButtonPress = () => {
    Linking.openURL('https://songbutler.app');
  }

  const onCloseButtonPress = () => {
    navigation.goBack();
  }

  return (
    <ImageBackground source={require('../assets/images/butler.png')} resizeMode='contain' style={styles.background} imageStyle={styles.backgroundImage}>
      <ScrollView style={styles.scrollView} contentContainerStyle={styles.container}>
        <View style={styles.closeButtonView}>
          <Pressable style={({pressed}) => ({
            marginTop: (Platform.OS === 'android' ? 16 : 0),
            marginBottom: 16,
            opacity: pressed ? 0.5 : 1
          })} onPress={onCloseButtonPress}>
            <Ionicons name='close-circle' color='gray' size={32} />
          </Pressable>
        </View>
        
        <Text style={styles.title}>Thank you for trying out SongButler!</Text>
        <Text style={styles.title}>There's a lot of stuff you can do now that you couldn't before!</Text>
        {features.map(f => <ApplicationFeature key={f.title} feature={f} style={styles.applicationFeature} />)}
        <CuteButton title='Visit our Website' style={{ marginTop: 24 }} onPress={onWebsiteButtonPress} />

        <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
      </ScrollView>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  background: {
    flex: 1,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  backgroundImage: {
    opacity: 0.25,
    alignSelf: 'flex-end',
    height: 350,
    top: undefined
  },
  scrollView: {
    paddingHorizontal: 16, 
    paddingTop: 16,
  },
  container: {
    flex: 1,
    alignItems: 'center',
  },
  closeButtonView: {
    alignSelf: 'flex-end', 
    backgroundColor: 'transparent'
  },
  title: {
    fontSize: 20, 
    textAlign: 'center',
    backgroundColor: 'transparent'
  },
  applicationFeature: {
    marginTop: 24, 
    backgroundColor: 'transparent'
  },
  featureTitle: {
    fontWeight: 'bold', 
    fontSize: 20, 
    textAlign: 'left',
    backgroundColor: 'transparent'
  },
  featureDescription: {
    fontSize: 16, 
    textAlign: 'justify',
    backgroundColor: 'transparent'
  },
});
